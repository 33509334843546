
// import Rails from "@rails/ujs"

console.log('Carregando Jq Plugins');

// require('metismenu')
//   require('metismenu/dist/metisMenu.js')
//   require('fullcalendar/dist/fullcalendar.js')

require('../../../vendor/assets/javascripts/autoNumeric/autoNumeric.js')
require('../../../vendor/assets/javascripts/chosen/chosen.jquery.js')

// require('xxxx')
// require('xxxx')
// require('xxxx')
// require('xxxx')
// Rails.start()

//= require fullcalendar/dist/fullcalendar.js
//= require fullcalendar/dist/locale/pt-br.js
